import { Component, OnInit } from '@angular/core';
import { faLink } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-aboutme',
  templateUrl: './aboutme.component.html',
  styleUrls: ['./aboutme.component.scss']
})
export class AboutmeComponent implements OnInit {

  faLink = faLink;

  bioList: string[] = [
    "In Basel geboren und aufgewachsen",
"eidg. Handelsdiplom",
"Alliance Française-Diplome, Lausanne",
"Schauspielausbildung, Theatre of Arts, Los Angeles, Kalifornien",
"Cambridge English Proficiency (CPE), Los Angeles",
"'Professional Designation in Marketing', University of California, Los Angeles (UCLA Extension)",
"PR-Fachfrau SPRI, Schweizerisches Public Relations Institut",
"Bachelor of Arts (Honours) in History, Open University, Milton Keynes, UK (2020)",
"1999 - 2018: knapp 20 Jahre Newsmoderatorin bei Telebasel",
"15 Jahre Produzentin bei Telebasel, Gastgeberin & redaktionelle Leitung eigener Talkshows",
"2017 - heute: Youtube-Kanal 'Tamara Wernli'",
"ab 2010 - heute: Beratung & Texte PR (neu: Social Media)",
"2010 - 2018: Kolumnistin Basler Zeitung",
"2019 - heute: Kolumnistin Weltwoche",
"2021 - heute: 'Die Tamara Show' bei Nebelspalter",
"Dreisprachig: Deutsch, Englisch und Französisch, fliessend in Wort & Schrift",
"spielt gerne Schach&Poker, süchtig nach Call of Duty"
  ]

  constructor() { }

  ngOnInit(): void {
  }

}

<main class="pt-3">
    <app-title title="Über mich" lead="Jobs und Bio"></app-title>

    <article class="row justify-content-center">

        <div class="col-12 col-md-6 col-lg-4 mt-2">
            <img class="img-fluid rounded" src="/assets/aboutme.jpg" alt="Tamara Wernli">
            <div class="d-flex justify-content-end">
                <span class=""><i>Liberal Award der Jungfreisinnigen ZH</i></span>
            </div>
        </div>

        <section class="col-12 col-md-6 col-lg-4 mt-2">

            <div class="card text-white bg-dark p-1">
                <h3 class="card-title">Texte</h3>
                <ul class="card-body">
                    <li>Kolumnen</li>
                    <li>Interviews</li>
                    <li>Reportagen</li>
                    <li>Portraits</li>
                    <li>Werbe- und PR-Texte</li>
                </ul>
            </div>
   
        <div class="card text-white bg-dark p-1">
            <h3 class="card-title">Referentin</h3>
            <p class="card-body">
                Ich bin auch als Referentin tätig. In meinen Beiträgen binde ich Themen wie Feminismus,
                Frauenquote, Eigenverantwortung, Hürden meistern am Arbeitsplatz und Selbstvertrauen ein –
                zusammengefasst unter dem Titel "No risk, no fun".
            </p>
        </div>
  
        <div class="card text-white bg-dark p-1" >
            <h3 class="card-title">Auszeichnung </h3>
            <p class="card-body">
             Für meine Arbeit als Kolumnistin & Youtuberin wurde ich 2019 mit dem „Liberal Award“ der Jungfreisinnigen Kanton Zürich (JFZH) ausgezeichnet.
            </p>
        </div>
     
        <div class="card text-white bg-dark p-1" >
            <h3 class="card-title">Videoblog</h3>
            <p class="card-body">
            Youtube-Kanal mit wöchentlichem Kommentar zu aktuellen Gesellschaftsthemen.
            </p>
            <a href="https://www.youtube.com/channel/UChBJrKb8HB1ViYagR7a-K4g" target="_blank"><fa-icon [icon]="faLink"></fa-icon> Zu meinem Youtube-Kanal</a> 
        </div>

        </section>


        <section class="col-12 col-md-6 col-lg-4 mt-2">
            <div class="card text-white bg-dark p-1" >
                <h3 class="card-title">Biographie</h3>
                <ul class="card-body">
                    <li *ngFor="let item of bioList">{{item}}</li> 
                </ul>
            </div>
        </section>
   


    </article>


  

    

    
<main>

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Kolumne} from '../../interfaces/kolumne'

@Component({
  selector: 'app-kolumne-teaser',
  templateUrl: './kolumne-teaser.component.html',
  styleUrls: ['./kolumne-teaser.component.scss']
})
export class KolumneTeaserComponent implements OnInit {
  @Input() kolumne: Kolumne;
  @Output() onreadClick: EventEmitter<any> = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }

  readClick = () => {
    this.onreadClick.emit();

    /* window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    }); */

  }

}

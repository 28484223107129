import { Component, OnInit } from '@angular/core';
import { faTwitter, faInstagram, faFacebook} from '@fortawesome/free-brands-svg-icons';



@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  faTwitter = faTwitter;
  faInstagram = faInstagram;
  faFacebook = faFacebook;
  collapseStatus: boolean = true;

  
  constructor() { }

  ngOnInit(): void {
  }

  toggleClass = (): void => {
    //trigger closing animation in menue after link selection, only on mobile
    let navbar = document.querySelector('.navbar');
    console.log(navbar);
    let expanded:boolean = navbar.classList.contains('navbar-expand');
    if(!expanded){
    let menuButton: HTMLElement = document.getElementById('togglerBtn') as HTMLElement;
    menuButton.click();
    }
  }
}
import { Injectable } from '@angular/core';
import {Kolumne} from '../interfaces/kolumne';
import  *  as  data  from  '../../../DATA/kolumnen.json'; //static data as placeholder
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class KolumnenService {
  kolumnen: Kolumne[] = (data  as  any).default;

  constructor() { 
  }

  public getKolumnen = ():Observable<Kolumne[]> => {
    return of(this.kolumnen); //placeholder till backend is up (should be replaced with http)
  }
}


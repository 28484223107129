<nav ngxNavbarDynamicExpand class="navbar navbar-dark">
    <a class="navbar-brand" href="#"></a>
  
    <button
      id="togglerBtn"
      class="navbar-toggler"
      type="button"
      (click)="collapse.toggle()"
      aria-controls="main-nav"
      aria-expanded="false"
      aria-label="Navigáció kapcsoló"
    >
      <span
        class="navbar-toggler-icon d-flex align-items-center justify-content-center"
      >
      </span>
    </button>
    <ngx-navbar-collapse id="main-nav" #collapse="ngxNavbarCollapse" [ngClass]="collapseStatus ? 'show' : ''">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" routerLink="/" (click)="toggleClass()"
            >Home<span class="sr-only">(current)</span></a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/kolumne" (click)="toggleClass()">Kolumne</a>
        </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/aboutme" (click)="toggleClass()">Über mich</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/contact" (click)="toggleClass()">Kontakt</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/impressum" (click)="toggleClass()">Impressum</a>
          </li>
         
      </ul>
      <ul class="navbar-nav">
        <li class="nav-item">
            <a class="nav-link nav-some" href="https://twitter.com/TamaraWernli" ><fa-icon [icon]="faTwitter"></fa-icon></a>
       
            <a class="nav-link nav-some" href="https://www.instagram.com/tamarawernli/" ><fa-icon [icon]="faInstagram"></fa-icon></a>
   
            <a class="nav-link nav-some" href="https://www.facebook.com/LadyTamy/" ><fa-icon [icon]="faFacebook"></fa-icon></a>
          </li>
      </ul>
    </ngx-navbar-collapse>
  </nav>

  
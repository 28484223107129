import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//bootstrap
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxNavbarModule } from 'ngx-bootstrap-navbar';

//custom
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

//Components
import { HeaderComponent } from './components/header/header.component';
import { NavComponent } from './components/nav/nav.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { KolumneComponent } from './components/kolumne/kolumne.component';
import { AboutmeComponent } from './components/aboutme/aboutme.component';
import { ContactComponent } from './components/contact/contact.component';
import { ImpressumComponent } from './components/impressum/impressum.component';
import { TitleComponent } from './components/title/title.component';
import { KolumneTeaserComponent } from './components/kolumne-teaser/kolumne-teaser.component';
import { KolumneReaderComponent } from './components/kolumne-reader/kolumne-reader.component';



const appRoutes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'kolumne', component: KolumneComponent},
  {path: 'aboutme', component: AboutmeComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'impressum', component: ImpressumComponent}
]

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavComponent,
    FooterComponent,
    HomeComponent,
    KolumneComponent,
    AboutmeComponent,
    ContactComponent,
    ImpressumComponent,
    TitleComponent,
    KolumneTeaserComponent,
    KolumneReaderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxNavbarModule,
    CollapseModule.forRoot(),
    RouterModule.forRoot(appRoutes,  { useHash: true /* , enableTracing: true*/ }),
    FontAwesomeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

<div class="sticky-top d-flex justify-content-end">
    <fa-icon [ngStyle]="{'color': 'white'}" [icon]="faTimesCircle" size="2x" (click)=closeClicked()></fa-icon>
</div>
    <h3>{{kolumne.title}}</h3>
    <h5>{{kolumne.lead}}</h5>
    <br>
    <p class="text-justify" [innerHTML]=kolumne.text></p><!--interpreting html-formatting-tags inside text-->
    <br>
    <span><i>{{kolumne.source}}</i></span>

       
<main class="pt-3" data-spy="scroll" data-target="#myScrollspy" data-offset="15">
  
    <app-title title="Impressum" lead="Rechtliches und Datenschutz"></app-title>


    <section class="row justify-content-center">
        <article class="pt-3 col-12 col-md-5">
            <p class="text-justify">
                Verantwortlich für den Inhalt dieser Website: Tamara Wernli Media<br><br>
                Die Wiedergabe von Artikeln ist nur mit Genehmigung von Tamara Wernli gestattet.
                Die Tamara Wernli Website enthält eventuell Links zu anderen Websites, auf deren Gestaltung und Inhalte sie keinen Einfluss hat. <br>
                Tamara Wernli Media ist nicht verantwortlich für den Inhalt dieser Websites und haften in keinem Fall für Verluste oder Schäden, die im Zusammenhang mit dem Zugriff auf solche Websites entstehen könnten.
            </p>
            <h5>Datenschutz</h5>
            <p>
                Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), lautet:
                Tamara Wernli
                E-Mail: office[at]tamarawernli[.]ch
                Webseite: tamarawernli.ch
            </p>
            <h5>Welche personenbezogenen Daten wir sammeln und warum wir sie sammeln</h5>
            <p>
                Wenn Sie auf diese Webseite zugreifen werden automatisch Informationen allgemeiner Natur erfasst. Diese Informationen werden im Server-Logfile erfasst und beinhalten die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers, Ihre IP-Adresse und ähnliches.
                Dies aus folgendem Grund:
            </p>
            <ul>
                <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website</li>
                <li>Sicherstellung einer reibungslosen Nutzung unserer Website</li>
                <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
                <li>zu weiteren administrativen Zwecken</li>
                <p>Ihre Daten werden nicht verwendet, um Rückschlüsse auf Ihre Person zu ziehen. Informationen dieser Art werden lediglich statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik zu optimieren.</p>  
            </ul>
            <h5>Speicherdauer</h5>
            <p>Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht mehr erforderlich sind. Dies ist für die Daten, die der Bereitstellung der Webseite dienen, grundsätzlich der Fall, wenn die jeweilige Sitzung beendet ist.</p>
            <h5>Verwendung von Scriptbibliotheken (Google Webfonts)</h5>
            <p>Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf dieser Website „Google Web Fonts“ der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; nachfolgend „Google“) zur Darstellung von Schriften.
                Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie unter https://www.google.com/policies/privacy/
            </p>
            <h5>Eingebettete Inhalte von anderen Websites</h5>
            <p>Beiträge auf dieser Website können eingebettete Inhalte beinhalten (z. B. Videos, Bilder, Beiträge etc.). Eingebettete Inhalte von anderen Websites verhalten sich exakt so, als ob der Besucher die andere Website besucht hätte.
                Diese Websites können Daten über Sie sammeln, Cookies benutzen, zusätzliche Tracking-Dienste von Dritten einbetten und Ihre Interaktion mit diesem eingebetteten Inhalt aufzeichnen, inklusive Ihrer Interaktion mit dem eingebetteten Inhalt, falls Sie ein Konto haben und auf dieser Website angemeldet sind.
            </p>
            <h5>Datenschutzerklärung für die Nutzung von Twitter</h5>
            <p>Beiträge auf dieser Website können eingebettete Inhalte beinhalten (z. B. Videos, Bilder, Beiträge etc.). Eingebettete Inhalte von anderen Websites verhalten sich exakt so, als ob der Besucher die andere Website besucht hätte.
                Diese Websites können Daten über Sie sammeln, Cookies benutzen, zusätzliche Tracking-Dienste von Dritten einbetten und Ihre Interaktion mit diesem eingebetteten Inhalt aufzeichnen, inklusive Ihrer Interaktion mit dem eingebetteten Inhalt, falls Sie ein Konto haben und auf dieser Website angemeldet sind.
                
                Datenschutzerklärung für die Nutzung von Twitter
                Auf unseren Seiten sind Funktionen des Dienstes Twitter eingebunden. Diese Funktionen werden angeboten durch die Twitter Inc., 795 Folsom St., Suite 600, San Francisco, CA 94107, USA. Durch das Benutzen von Twitter und der Funktion „Re-Tweet“ werden die von Ihnen besuchten Webseiten mit Ihrem Twitter-Account verknüpft und anderen Nutzern bekannt gegeben. Dabei werden u.a. Daten wie IP-Adresse, Browsertyp, aufgerufene Domains, besuchte Seiten, Mobilfunkanbieter, Geräte- und Applikations-IDs und Suchbegriffe an Twitter übertragen.
                Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Twitter erhalten. Aufgrund laufender Aktualisierung der Datenschutzerklärung von Twitter, weisen wir auf die aktuellste Version unter (http://twitter.com/privacy) hin.
                Ihre Datenschutzeinstellungen bei Twitter können Sie in den Konto-Einstellungen unter http://twitter.com/account/settings ändern. Bei Fragen wenden Sie sich an privacy@twitter.com.</p>
            <h5>Eingebettete YouTube-Videos</h5>
            <p>Auf einigen unserer Webseiten betten wir YouTube-Videos ein. Betreiber der entsprechenden Plugins ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA (nachfolgend „YouTube“). Wenn Sie eine Seite mit dem YouTube-Plugin besuchen, wird eine Verbindung zu Servern von YouTube hergestellt. Dabei wird YouTube mitgeteilt, welche Seiten Sie besuchen. Wenn Sie in Ihrem YouTube-Account eingeloggt sind, kann YouTube Ihr Surfverhalten Ihnen persönlich zuzuordnen. Dies verhindern Sie, indem Sie sich vorher aus Ihrem YouTube-Account ausloggen.
                Wird ein YouTube-Video gestartet, setzt der Anbieter Cookies ein, die Hinweise über das Nutzerverhalten sammeln.
            </p>
            <h5>Social Plugins</h5>
            <p>Auf unseren Webseiten werden Socialmedia Plugins eingesetzt. Über diese Plugins werden unter Umständen Informationen, zu denen auch personenbezogene Daten gehören können, an den Dienstebetreiber gesendet und ggf. von diesem genutzt. Wo möglich wurden diese Plugins über die sog «Shariff»Lösung implementiert um zu verhindern, dass die Plugins bereits beim Besuch der Webseite Daten an  die Betreiber übermitteln. Wir erfassen selbst keine personenbezogenen Daten mittels der Social Plugins oder über deren Nutzung.
                Wir haben keinen Einfluss darauf, welche Daten ein aktiviertes Plugin erfasst und wie diese durch den Anbieter verwendet werden. Derzeit muss davon ausgegangen werden, dass eine direkte Verbindung zu den Diensten des Anbieters ausgebaut wird sowie mindestens die IP-Adresse und gerätebezogene Informationen erfasst und genutzt werden. Ebenfalls besteht die Möglichkeit, dass die Diensteanbieter versuchen, Cookies auf dem verwendeten Rechner zu speichern. Welche konkreten Daten hierbei erfasst und wie diese genutzt werden, entnehmen Sie bitte den Datenschutzhinweisen des jeweiligen Diensteanbieters. Falls Sie zeitgleich bei Facebook angemeldet sind, kann Facebook Sie als Besucher einer bestimmten Seite identifizieren.
            </p>
            <h5>Wie wir Ihre Daten schützen</h5>
            <p>Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
            </p>
            <h5>Änderungen</h5>
            <p>Wir können diese Datenschutzerklärung jederzeit ohne Vorankündigung anpassen. Es gilt die jeweils aktuelle, auf unserer Website publizierte Fassung.
            </p>
            <h5>Fragen an den Datenschutzbeauftragten</h5>
            <p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz zu Beginn der Datenschutzerklärung aufgeführten, verantwortlichen Person in unserer Organisation.
            </p>
            </article>
    </section>
    
<main>
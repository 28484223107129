import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { KolumnenService } from 'src/app/services/kolumnen.service';
import {Kolumne} from '../../interfaces/kolumne';


@Component({
  selector: 'app-kolumne',
  templateUrl: './kolumne.component.html',
  styleUrls: ['./kolumne.component.scss']
})
export class KolumneComponent implements OnInit {
  reader: boolean = false;
  activeKolumne: Kolumne;

  kolumnen$: Observable<Kolumne[]>; // to load it async in dom

  constructor(private kolumnenService: KolumnenService) { }

  ngOnInit(): void {
    //this.kolumnenService.getKolumnen().subscribe(kolumnen => this.kolumnen = kolumnen);
    this.kolumnen$ = this.kolumnenService.getKolumnen();
  }

  onReadClick = (kolumne: Kolumne):void => {
    this.reader = !this.reader;
    this.activeKolumne = kolumne;
  }

  onCloseClicked = ():void => {
    this.reader = false;
  }
  

}


<main class="pt-3" data-spy="scroll" data-target="#myScrollspy" data-offset="15">
  
    <app-title title="Kontakt" lead=""></app-title>

    <article class="row justify-content-center">

        <div class="col-12 col-md-4 mt-2">
            <img class="img-fluid rounded" src="/assets/contact.jpg" alt="Tamara Wernli">
        </div>

        <section class="col-12 col-md-5 mt-2">
            <p>
                Für geschäftliche Anfragen: <br><fa-icon [icon]="faEnvelope"></fa-icon> office[at]tamarawernli[.]ch <br>
                Bitte haben Sie Verständnis, wenn ich
                aufgrund der grossen Menge nicht alle Emails
                beantworten kann.<br><br>

                Bitte setzen Sie mich auf keinen Verteiler
                und senden Sie mir keine Bücher, Buch-Rezensionsanfragen, Umfragen, Pressemitteilungen und Newsletter zu,
                auch keine Autogrammkarten zum Signieren. Danke.
            </p>

        </section> 
    
<main>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { Kolumne } from 'src/app/interfaces/kolumne';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';


@Component({
  selector: 'app-kolumne-reader',
  templateUrl: './kolumne-reader.component.html',
  styleUrls: ['./kolumne-reader.component.scss']
})
export class KolumneReaderComponent implements OnInit {
  @Input() kolumne: Kolumne;
  @Output() onCloseClicked: EventEmitter<any> = new EventEmitter();
  faTimesCircle = faTimesCircle;
  

  constructor() { }

  ngOnInit(): void {
  }

  closeClicked = ():void => {
    this.onCloseClicked.emit();
  }

}

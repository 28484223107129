<main class="pt-3" data-spy="scroll" data-target="#myScrollspy" data-offset="15">
  
    <app-title *ngIf="!reader" title="Kolumnen" lead="Eine kleine Auswahl meiner Texte"></app-title>

    <div>
        <img class="img-fluid rounded mt-2" src="/assets/kolumne.jpg" alt="">
        <div class="d-flex justify-content-end">
            <span class=""><i>Foto: Gabriel Hill</i></span>
        </div>
    </div>

    <section class="row justify-content-center" *ngIf="reader">
        <article class="pt-3 col-12 col-md-10 col-lg-8 readerContainer">
        <app-kolumne-reader [kolumne]=activeKolumne (onCloseClicked)=onCloseClicked()></app-kolumne-reader>
        </article>
    </section>


    <article *ngIf="!reader" class="row justify-content-center">
        <div class="col-12 col-md-8 mt-3" >
        <p class="text-justify">
            Ich bin selbständige Medienunternehmerin und tätig als Kolumnistin, Autorin, Referentin und Videobloggerin bei Youtube.
            In meiner wöchentlichen Rubrik 'Tamaras Welt' in der Weltwoche schreibe ich über aktuelle Gesellschaftsthemen.
        </p>
        </div>
    </article>


    <section *ngIf="!reader" class="row justif]-content-center">
       <div class="col-12 col-lg-4" *ngFor='let kolumne of kolumnen$ | async'>
         <app-kolumne-teaser (onreadClick)=onReadClick(kolumne) [kolumne]=kolumne></app-kolumne-teaser>
      </div>
    </section>
    
<main>

<main class="pt-3">

    <app-title title="Tamara Wernli" lead="Publizistin, Video-Creator, Unternehmerin"></app-title>

    <div class="row justify-content-center">
        <div class="col-12 col-md-8">
        <img class="img-fluid rounded mt-2" src="/assets/home.jpg" alt="Tamara Wernli">
    </div>
    </div>

    <article class="row justify-content-center">
        <div class="col-12 col-md-8 mt-3" >
        <p class="text-justify">
            Falls Sie nicht zu den schnell beleidigten Gemütern zählen, dann schauen Sie sich gerne meine Youtube-Videos an. Darin setze ich mich mit 
            kontroversen Themen der Gesellschaft auseinander und verpacke sie auch mit einem Augenzwinkern in satirische Sketche. In meinen Videos und Texten versuche ich, Blickwinkel & Perspektiven aufzuzeigen, die in den Mainstream-Medien häufg zu kurz kommen.<br><br>
            Für meine Arbeit als Kolumnistin & Videobloggerin wurde ich 2019 mit dem „Liberal Award“ der Jungfreisinnigen Kanton Zürich (JFZH) ausgezeichnet.
        </p>
        <p class="text-justify">
            Ein Porträt über meine Wenigkeit: "Die Männerversteherin" (NZZ, Neue Zürcher Zeitung)<br>
            <a href="/assets/portraitNZZ.pdf" download><fa-icon [icon]="faLink"></fa-icon> download pdf</a> <br><br>
        </p>
        <p class="text-justify">
            "Die Basler FDP hätte Tamara Wernli einfach gernli" (BZ-Basel)<br>
            <a href="https://www.bzbasel.ch/basel/basel-stadt/nationalratswahlen-die-basler-fdp-hatte-tamara-wernli-einfach-gernli-ld.1508588" target="blank"><fa-icon [icon]="faLink"></fa-icon> zum Artikel</a> <br><br>
        </p>
            
        </div> 
    </article>

    <div class="divide"></div>

    <section class="row pb-4 justify-content-center">

        <div class="col-12 titlebreak justify-content-center">
            <h6 class="text-center mb-4">Social Media</h6>
        </div>
        
        <div id="youtubeArea" class="col-12 col-md-6 col-lg-4">
            <div><i>Abonnieren Sie meinen Youtube-Kanal</i></div>
            <div class="g-ytsubscribe" data-channelid="UChBJrKb8HB1ViYagR7a-K4g" data-layout="default" data-theme="dark" data-count="default"></div>
            
            <div class="video-container mb-2">
                <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/BWz8XsNdr3A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
        
        <div id="twitterArea" class="col-12 col-md-6 col-lg-4">
            <div><i>und folgen Sie mir auf Twitter</i></div>
            <a href="https://twitter.com/TamaraWernli?ref_src=twsrc%5Etfw" class="twitter-follow-button m-1" data-size="large" data-lang="de" data-dnt="true" data-show-count="false">Follow @TamaraWernli</a>
            <blockquote class="twitter-tweet" data-dnt="true" data-theme="dark"><p lang="de" dir="ltr">Als Frau kann ich bestätigen: Nichts hilft mir bei der Stärkung meines Selbstwertgefühls, meiner Selbstbestimmung und meiner gesellschaftlichen Stellung mehr, als wenn auf irgendeinem Volksfest 1 Ballermann Song verboten wird. <a href="https://twitter.com/hashtag/Layla?src=hash&amp;ref_src=twsrc%5Etfw">#Layla</a></p>&mdash; Tamara (@TamaraWernli) <a href="https://twitter.com/TamaraWernli/status/1546947186373582850?ref_src=twsrc%5Etfw">July 12, 2022</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
        </div>
        
<main>
  


    
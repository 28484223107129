import { NONE_TYPE } from '@angular/compiler';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { Router, NavigationEnd } from '@angular/router';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})




export class HomeComponent implements OnInit, OnDestroy {
  faLink = faLink;
  private twitter: any;
  private yt: any;
 
  

  constructor(private _router : Router) { 

   this.initTwitterWidget();
   this.initYoutubeSubscribeButton();
  }

  private initTwitterWidget = () => {
    this.twitter = this._router.events.subscribe(val => {
      if (val instanceof NavigationEnd && val.url == '/') {
        https://developer.twitter.com/en/docs/twitter-for-websites/javascript-api/guides/set-up-twitter-for-websites
        (<any>window).twttr = (function (d, s, id) {
          let js: any;
          let fjs = d.getElementsByTagName(s)[0];
          let t = (<any>window).twttr || {};
          if (d.getElementById(id)) return t;

          js = d.createElement(s);
          js.id = id;
          js.defer = true;
          js.async = true;
          js.src = "https://platform.twitter.com/widgets.js";
          fjs.parentNode.insertBefore(js, fjs);

          t._e = [];
          t.ready = function (f: any) {
              t._e.push(f);
          };

          return t;
        }(document, "script", "twitter-wjs"));

        if ((<any>window).twttr.ready()){
          (<any>window).twttr.widgets.load(document.getElementById("twitterArea"));
        }
      }
    });
  }

  private initYoutubeSubscribeButton = () => {
    this.yt = this._router.events.subscribe(val => {
      if (val instanceof NavigationEnd && val.url == '/') {
            //from https://developers.google.com/analytics/devguides/reporting/embed/v1/custom-components?hl=fr
        (function(w,d,s,g,js,fjs){
          g= (<any>window).gapi||( (<any>window).gapi={});
          g.ytsubscribe={q:[],ready:function(cb){
            this.q.push(cb)
          }};
          js=d.createElement(s);
          fjs=d.getElementsByTagName(s)[0];
          js.defer = true;
          js.async = true;
          js.src='https://apis.google.com/js/platform.js';
          fjs.parentNode.insertBefore(js,fjs);
          js.onload=function(){
            g.ytsubscribe.go()
          };
        }(window,document,'script'));
      }
    })
  }
  

  ngOnDestroy(): void {

    this.cleanUpYTMess();
  }

  private cleanUpYTMess = () => {
    //there is certainly a better solution, but for now clean up scripts and iframes from yt manually when route is switched.
    //for twitter widget it seems no problem, but yt frames generate cors error when not on home component.
    let scripts = document.getElementsByTagName('script');
    Array.from(scripts).filter(s => s.outerHTML.toString().includes("https://apis.google.com/")).forEach(s => s.remove());

    let iframes = document.getElementsByTagName('iframe');
    Array.from(iframes).forEach(f => f.remove());
  }

  ngOnInit(): void {}
  }